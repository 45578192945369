


































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RoomModel } from '../models/RoomModel'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
const UserStore = namespace('UserStore')
import { UserModel } from '@/core/models/UserModel'

import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import Badge from '@/components/Badge.vue'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'

@Component({
  name: 'Room',
  components: {
    Swiper,
    SwiperSlide,
    BaseButton,
    BaseIcon,
    Badge,
    TippyComponent,
  },
})
export default class Room extends Vue {
  @Prop({ required: true })
  room!: RoomModel

  @Prop(Boolean)
  readonly editable!: boolean

  @RoomsStore.Getter
  public previousViewsById!: (id: number) => number | undefined

  @UserStore.Getter
  public currentUser!: UserModel

  swiperOptions = {
    slidesPerView: 1,
    pagination: {
      el: '.Room__pagination',
      clickable: true,
    },
  }

  get previousViews(): number | undefined {
    return this.room.id ? this.previousViewsById(this.room.id) : undefined
  }
}










































































import { Nullable } from '@/types'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import Room from '@/modules/MyRoomsPage/components/Room.vue'

import { CallInterface, CallbackInterface } from '../types'
import { toLocalDuration } from '@/utils/durationFormatter'

@Component({
  name: 'EventDetails',
  components: {
    Room,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  event!: CallInterface | CallbackInterface

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  get room(): Nullable<RoomModel> {
    return this.event?.place_id
      ? this.roomList.find((item) => item.id === this.event?.place_id) || null
      : null
  }

  toLocalDuration = toLocalDuration
}
